@import 'Styles/includes';

.CardQuote {
    $root: &;

    display: flex;
    flex-direction: column-reverse;
    position: relative;

    @include media(M) {
        flex-direction: row-reverse;
    }

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    &__Blockquote {
        flex: 0 0 auto;
        align-self: center;
        position: relative;
        margin: -32px 16px 0;
        padding: 32px 16px 32px 24px;
        position: relative;
        border-radius: 4px;
        background-color: $red-5;

        @include media(M) {
            width: 50%;
            margin: 0;
            padding: 48px 32px 32px 48px;
        }

        @include media(L) {
            padding: 56px 32px 40px 56px;
        }
    }

    &__Quotation {
        position: absolute;
        top: 16px;
        left: 16px;
        width: 37px;
        height: 32px;
        fill: $red;
        opacity: .2;

        @include media(M) {
            top: 32px;
            left: 24px;
            width: 48px;
            height: 40px;
        }

        @include media(L) {
            top: 40px;
            left: 32px;
            width: 56px;
            height: 48px;
        }
    }

    &__Quote {
        @include textstyle(quote);
    }

    &__Cite {
        @include textstyle(body-small);

        display: block;
        margin-top: 32px;
        min-height: 24px;
        padding-right: 56px;

        @include media(M) {
            margin-top: 24px;
        }
    }

    &__Name {
        font-weight: $bold;
    }

    &__Image {
        position: relative;
        flex: 0 0 auto;
        order: 1;

        @include media(M) {
            order: 0;
            width: calc(50% + 50px);
            margin-left: -50px;
        }

        @include media(L) {
            width: calc(50% + 75px);
            margin-left: -75px;
        }

        &::after {
            content: '';
            display: block;
            height: 0;
            padding-bottom: #{percentage(456 / 753)};
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__Button {
        @extend %button--round-arrow-white;

        position: absolute !important;
        bottom: 24px;
        right: 24px;

        @include media(M) {
            bottom: 32px;
            right: 32px;
        }

        #{$root}:hover & {
            @extend %button--round-arrow-white, :hover;
        }
    }
}
