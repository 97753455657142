@import 'Styles/includes';

.CardDonation {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    background: white;

    &--HideImage {
        background: $red-5;
    }

    &__Shell {
        display: none;
        border-radius: 4px;

        #{$root}--Large & {
            display: block;
        }

        #{$root}--Odd & {
            @extend %shell--before;

            &::before {
                top: 0px;
                left: -1216px;
                width: 1452px;
                height: 1030px;
            }
        }

        #{$root}--Even & {
            @extend %shell--after;

            &::after {
                bottom: 0;
                right: -1216px;
                width: 1452px;
                height: 1030px;
                transform: rotate(180deg);
            }
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 1;
    }

    &__Image {
        position: relative;
        width: 100%;

        &::before {
            content: '';
            display: block;
            height: 0;
            width: 100%;
            padding-bottom: #{percentage(9/16)};
        }

        #{$root}--HasImage &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            background: black;
            opacity: .05;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        #{$root}--HideImage & {
            display: none;
        }
    }

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        padding: 12px 16px;
        color: $grey-90;
        border-radius: 4px;
        overflow: hidden;
        transition: color .4s .1s ease-in-out;

        @include media(M) {
            padding: 18px 24px;
        }

        #{$root}--ShowImage & {
            padding: 18px 16px;

            @include media(M) {
                padding: 30px 24px;
            }
        }

        #{$root}--Large & {
            align-items: center;
            padding: 32px;
            text-align: center;

            @include media(M) {
                padding: 40px;
            }
        }

        #{$root}--HideImage:hover & {
            color: white;

            @media(hover: none) {
                color: $grey-90;
            }
        }

        &::before {
            content: '';
            display: block;
            position: absolute;
            margin: auto;
            width: 32px;
            height: 32px;
            width: 200%;
            height: 0;
            padding-bottom: 200%;
            border-radius: 50%;
            background: $red;
            transform-origin: center center;

            #{$root}--Small & {
                top: 50%;
                right: calc(-100% + 40px);
                transform: translateY(-50%) scale(0);
                transition: transform .5s ease-in-out;
            }

            #{$root}--Large & {
                top: calc(100% - 58px);
                left: 50%;
                transform: translate(-50%, -50%) scale(0);
                transition: transform .8s ease-in-out;
            }

            #{$root}--HideImage#{$root}--Small:hover & {
                transform: translateY(-50%) scale(1);

                @media(hover: none) {
                    transform: translateY(-50%) scale(0);
                }
            }

            #{$root}--HideImage#{$root}--Large:hover & {
                transform: translate(-50%, -50%) scale(1);

                @media(hover: none) {
                    transform: translate(-50%, -50%) scale(0);
                }
            }
        }
    }

    &__Title {
        @include textstyle(h6);

        position: relative;
        padding-right: 48px;

        #{$root}--Large & {
            @include textstyle(h3);

            padding-right: 0;
            margin-bottom: 16px;
        }

        #{$root}--ShowImage & {
            font-size: 2rem;
        }
    }

    &__Text {
        @include textstyle(body-small);

        display: none;
        position: relative;
        margin-bottom: 16px;
        max-width: 366px;

        #{$root}--Large & {
            display: block;
        }
    }

    &__Button {
        @extend %button--round-arrow-red-small;

        margin: auto !important;

        #{$root}--ShowImage & {
            @extend %button--round-arrow-pink;
        }

        #{$root}--ShowImage:hover & {
            @extend %button--round-arrow-pink, :hover;
        }

        #{$root}--Small & {
            position: absolute !important;
            top: 0;
            bottom: 0;
            right: 16px;

            @include media(M) {
                right: 24px;
            }
        }

        #{$root}--Large & {
            margin: auto auto 0 !important;
            justify-self: flex-end;
        }
    }
}
