@import 'Styles/includes';

.DonationList {
    $root: &;

    margin-top: 48px;
    margin-bottom: 48px;

    @include media(M) {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-bottom: 80px;
    }

    &__Wrap {
        @include wrap;
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
        margin-bottom: -16px;

        @include media(ML) {
            margin-left: -24px;
            margin-bottom: -24px;
        }

        @include media(L) {
            margin-left: -38px;
        }
    }

    &__Item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        margin-left: 16px;
        margin-bottom: 16px;
        width: calc(100% - 16px);

        @include media(ML) {
            margin-left: 24px;
            margin-bottom: 24px;
        }

        @include media(L) {
            margin-left: 38px;
        }

        &--Third {
            @include media(M) {
                width: calc(#{percentage(1/3)} - 16px);
            }

            @include media(ML) {
                width: calc(#{percentage(1/3)} - 24px);
            }

            @include media(L) {
                width: calc(#{percentage(1/3)} - 38px);
            }
        }

        &--Half {
            @include media(M) {
                width: calc(50% - 16px);
            }

            @include media(ML) {
                width: calc(50% - 24px);
            }

            @include media(L) {
                width: calc(50% - 38px);
                margin-bottom: 38px;
            }
        }
    }
}
