@import 'Styles/includes';

.SlickSlider {
    $root: &;

    position: relative;

    &__Button {
        @extend %button--round-chevron;

        position: absolute !important;
        top: -64px;
        right: 0;
        cursor: pointer;

        @include media(M) {
            top: -88px;
        }

        #{$root}--ProgressBar & {
            top: auto;
            right: auto;
            bottom: -64px;
            left: 52px;

            @include media(M) {
                top: auto;
                bottom: -80px;
                left: 64px;
            }
        }

        &--Prev {
            right: 52px;
            transform: rotate(180deg);

            @include media(M) {
                right: 64px;
            }

            #{$root}--ProgressBar & {
                right: auto;
                left: 0;

                @include media(M) {
                    right: auto;
                    left: 0;
                }
            }
        }

        &:global(.slick-disabled) {
            @extend %button--round-chevron, [disabled];

            cursor: auto;
        }
    }

    :global {
        .slick-list {
            position: relative;
            overflow: visible;

            @include media(M) {
                margin-right: -16px;
            }

            @include media(ML) {
                margin-right: -24px;
            }

            @include media(L) {
                margin-right: -38px;
            }
        }

        .slick-track {
            display: flex;
            min-width: 100%;
        }

        .slick-slide {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            height: auto;
            width: 100%;
            padding-right: 16px;

            @include media(M) {
                #{$root}--Third & {
                    width: #{percentage(1/3)};
                }
            }

            @include media(ML) {
                padding-right: 24px;
            }

            @include media(L) {
                padding-right: 38px;
            }

            > div {
                flex: 1 0 auto;
                display: flex;
                flex-direction: column;
            }
        }

        .slick-dots {
            display: flex !important;
            position: absolute;
            bottom: -46px;
            left: (92px + 16px);
            right: 0;
            height: 4px;
            background: $grey-20;

            @include media(M) {
                bottom: -56px;
                left: (112px + 24px);
            }

            li {
                flex: 1 0 auto;
                position: relative;

                button {
                    display: none;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: $red;
                    transition: width $transition;
                }

                &.slick-active {
                    ~ li::before {
                        width: 0;
                    }
                }
            }
        }
    }

    &__Item {
        flex: 1 0 auto;
        display: flex !important;
        flex-direction: column;
    }
}
