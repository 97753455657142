@import 'Styles/includes';

.CardArticle {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 4px;
    background: $red-5;

    &__Shell {
        @extend %shell--before;

        border-radius: 4px;

        &::before {
            transition: transform $transition;
        }

        #{$root}--First & {
            &::before {
                top: -635px;
                left: 0px;
                width: 1010px;
                height: 716px;
                transform: rotate(180deg);
            }
        }

        #{$root}--Second & {
            &::before {
                top: 0;
                left: 219px;
                width: 896px;
                height: 636px;
                transform: rotate(180deg);
            }
        }

        #{$root}--Third & {
            &::before {
                top: -225px;
                left: 150px;
                width: 606px;
                height: 430px;
            }
        }

        #{$root}--Fourth & {
            &::before {
                top: 220px;
                right: 99px;
                width: 896px;
                height: 636px;
            }
        }

        #{$root}:hover & {
            &::before {
                transform: scale(1.05);
            }
        }

        #{$root}--First:hover &,
        #{$root}--Second:hover & {
            &::before {
                transform: rotate(180deg) scale(1.05);
            }
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        z-index: 1;
    } // Must be placed before __Content

    &__Content {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 32px 16px;
        color: $grey-90;

        @include media(M) {
            padding: 32px 24px;
        }
    }

    &__Title {
        @include textstyle(h5);
    }

    &__Text {
        margin-top: 16px;
        font-size: 1.6rem;
        font-weight: normal;
        line-height: 1.5;
    }

    &__Footer {
        justify-self: flex-end;
        margin-top: auto;
        padding-top: 32px;
    }

    &__Button {
        display: block;
        font-weight: $bold;
        text-decoration: underline;
        transition: color $transition;

        &::after {
            content: '';
            display: inline-block;
            position: relative;
            top: 1px;
            width: 14px;
            height: 14px;
            background: url('#{$assetsPath}/img/arrow-large--grey-90.svg') no-repeat center center;
            background-size: contain;
            margin-left: 8px;
            transition: background-image $transition;
        }

        #{$root}:hover & {
            color: $red;

            &::after {
                background-image: url('#{$assetsPath}/img/arrow-large--red.svg');
            }
        }
    }

    &__Link {
        @include href-external {
            + #{$root}__Content #{$root}__Button::after {
                background-image: url('#{$assetsPath}/img/external--grey-90.svg')
            }

            &:hover {
                + #{$root}__Content #{$root}__Button::after {
                    background-image: url('#{$assetsPath}/img/external--red.svg');
                }
            }
        }
    }
}
