@import 'Styles/includes';

.ArticleSlider {
    $root: &;

    margin-top: 48px;
    margin-bottom: 48px;
    overflow: hidden;

    @include media(M) {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    @include media(ML) {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    &__Wrap {
        @include wrap;
    }
}
