@import 'Styles/includes';

.ExpandableContent {
    $root: &;

    position: relative;
    padding-top: 48px;
    padding-bottom: 48px;
    background: $red-5;

    @include media(M) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @include media(ML) {
        padding-top: 80px;
        padding-bottom: 80px;
    }

    &__Shell {
        @extend %shell--before;
        @extend %shell--after;

        #{$root} & {
            &::before {
                top: -953px;
                left: -1904px;
                width: 2204px;
                height: 1546px;
            }

            &::after {
                top: 150px;
                right: -1904px;
                width: 2204px;
                height: 1546px;
                transform: rotate(-150deg);
            }
        }
    }

    &__Wrap {
        @include wrap;
    }

    &__Container {
        max-width: map-get($maxwidths, content);
        margin: 0 auto;
    }

    &__Title {
        margin: 0 0 16px;
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1.33;

        @include media(ML) {
            margin-bottom: 24px;
            font-size: 3.8rem;
        }
    }

    &__Cropped {
        max-height: none;
        position: relative;
        transition: max-height $transition;

        &::after {
            content: '';
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 104px;
            background-image: linear-gradient(to top, $red-5, rgba($red-5, .82) 50%, rgba($red-5, 0) 100%);
        }

        #{$root}--Cropped & {
            max-height: 300px;
            overflow: hidden;

            &::after {
                display: block;
            }
        }
    }

    &__Content {
        :global {
            @extend %richtext;
            @extend %gravityforms;
        }

        :global {
            .factbox,
            .wp-block-sage-emphasis {
                background-color: $red-10 !important;
            }


            blockquote {
                &::before {
                    opacity: 0.2 !important;
                    z-index: 0 !important;
                }
            }
        }
    }

    &__Button {
        #{$root} & {
            margin-top: 16px;
        }

        span {
            display: inline-block;
            position: relative;
            top: 1px;
            width: 16px;
            height: 16px;
            margin-left: 8px;

            &::before,
            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 16px;
                height: 2px;
                margin: auto;
                background: $base-color;
                transition: background $transition;
            }

            &::after {
                transform: rotate(90deg);

                #{$root}:not(#{$root}--Cropped) & {
                    display: none;
                }
            }
        }

        &:hover {
            span {
                &::before,
                &::after {
                    background: white;
                }
            }
        }
    }
}
