@use 'sass:math';

%gravityforms {
    .gform {
        &_wrapper {
            h2 {
                @include textstyle(h2);

                margin-bottom: 0;
            }

            h3 {
                @include textstyle(h3);

                margin-bottom: 0;
            }

            ul {
                list-style: none;
                margin: 0;
            }
        }

        // For hiding iframe used when ajax activated
        &_wrapper,
        &_confirmation_wrapper {
            + iframe {
                width: 0 !important;
                height: 0 !important;
                border: 0 !important;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        &_drop_area {
            @extend %form__dropzone;
        }

        &_description {
            margin-top: 24px;
            display: block;
        }

        &_required_legend,
        &_fileupload_rules {
            @extend %form__description;
        }

        &_required_legend {
            margin-bottom: 0;
        }

        &_fields {
            display: flex;
            flex-wrap: wrap;
            margin-left: -8px;

            @include media(M) {
                margin-left: -16px;
            }
        }

        &_submission_error {
            padding: 12px;
            margin: 16px 0 !important;
            font-size: 1.6rem !important;
            border-radius: 4px;
            background-color: $red-5;
            border: 1px solid $red !important;
        }

        &_button {
            @extend %form__submit;

            margin-top: 24px;
        }

        &_validation_container {
            display: none !important;
            left: -9000px;
            position: absolute !important;
        }
    }

    .gfield {
        @extend %form__wrapper;

        margin-left: 8px;
        width: calc(100% - 8px);

        @include media(M) {
            margin-left: 16px;
            width: calc(100% - 16px);
        }

        &--type-submit {
            display: flex;
            flex-direction: column;

            .gform_submit_button {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
            }

            .gform_button {
                @extend %form__submit--primary;

                flex: 1 1 auto;
                margin-top: 0;
            }
        }

        &--width-half {
            @include media(M) {
                width: calc(50% - 16px);
            }
        }

        &--width-third {
            @include media(L) {
                width: calc(#{percentage(math.div(1, 3))} - 16px);
            }
        }

        &--width-two-thirds {
            @include media(L) {
                width: calc(#{percentage(math.div(2, 3))} - 16px);
            }
        }

        &--width-quarter {
            @include media(M) {
                width: calc(50% - 16px);
            }

            @include media(L) {
                width: calc(25% - 16px);
            }
        }

        &--width-three-quarter {
            @include media(M) {
                width: calc(50% - 16px);
            }

            @include media(L) {
                width: calc(75% - 16px);
            }
        }

        &--width-one-sixth {
            @include media(M) {
                width: calc(#{percentage(math.div(1, 3))} - 16px);
            }

            @include media(L) {
                width: calc(#{percentage(math.div(1, 6))} - 16px);
            }
        }

        &--width-five-sixths {
            @include media(M) {
                width: calc(#{percentage(math.div(2, 3))} - 16px);
            }

            @include media(L) {
                width: calc(#{percentage(math.div(5, 6))} - 16px);
            }
        }

        &--width-one-twelfth {
            @include media(M) {
                width: calc(#{percentage(math.div(1, 6))} - 16px);
            }

            @include media(L) {
                width: calc(#{percentage(math.div(1, 12))} - 16px);
            }
        }

        &--width-five-twelfths {
            @include media(M) {
                width: calc(#{percentage(math.div(5, 12))} - 16px);
            }
        }

        &--width-seven-twelfths {
            @include media(M) {
                width: calc(#{percentage(math.div(7, 12))} - 16px);
            }
        }

        &--width-eleven-twelfths {
            @include media(M) {
                width: calc(#{percentage(math.div(5, 6))} - 16px);
            }

            @include media(L) {
                width: calc(#{percentage(math.div(11, 12))} - 16px);
            }
        }

        &--text,
        &--textarea,
        &--select,
        &--multiselect,
        &--number,
        &--time,
        &--date,
        &--email,
        &--website,
        &--phone,
        &--fileupload {
            &:not(.gfield--has-description.field_description_above):not(.hidden_label) {
                margin-top: 0px !important;
            }

            &:not(.gfield--has-description.field_description_above):not(.hidden_label) .gfield_label {
                @extend %form__label--inside;
            }

            &.gfield_error {
                > .gfield_label {
                    @extend %form__label--error;
                }
            }

            &:not(.gfield--width-full) + .gfield--type-submit {
                justify-content: flex-end;

                .gform_submit_button {
                    max-height: 56px;
                }
            }
        }

        &_label {
            @extend %form__label;
        }

        &.hidden_label {
            margin-top: 24px !important;

            > .gfield_label {
                display: none;
            }
        }

        &_checkbox,
        &_container_consent {
            input {
                @extend %form__checkbox;
            }

            label {
                @extend %form__label-checkbox;
            }

            input:checked + label {
                @extend %form__label-checkbox--checked;
            }

            input:focus + label {
                @extend %form__label-checkbox--focus;
            }
        }

        &_radio {
            input {
                @extend %form__radio;
            }

            label {
                @extend %form__label-radio;
            }

            input:checked + label {
                @extend %form__label-radio--checked;
            }

            input:focus + label {
                @extend %form__label-radio--focus;
            }
        }

        &_container {
            input:not([type='checkbox']):not([type='radio']):not([type='file']) {
                @extend %form__input;
            }

            input[type='file'] {
                @extend %form__file;
            }

            textarea {
                @extend %form__textarea;
            }

            select {
                @extend %form__select;
            }

            &--select {
                @extend %form__select-container;
            }
        }

        &_error {
            input:not([type='checkbox']):not([type='radio']):not([type='file']) {
                @extend %form__input--error;
            }

            input[type='file'] {
                @extend %form__file--error;
            }

            textarea {
                @extend %form__textarea--error;
            }

            select {
                @extend %form__select--error;
            }
        }

        &_gfield_required {
            margin-left: 4px;
        }

        &_required_asterisk {
            color: $red;
        }

        &_consent_label {
            + .gfield_required_asterisk,
            > .gfield_required_asterisk {
                display: none;
            }
        }

        &_visibility_hidden {
            left: -9999px;
            position: absolute;
            visibility: hidden;
        }

        // If primary label is hidden, make sure asterisk is still added
        &.hidden_label {
            .gfield_consent_label {
                &::after {
                    content: '*';
                    display: inline-block;
                    margin-top: -2px;
                    margin-left: 4px;
                    color: $red;
                    font-size: 1.8rem;
                }
            }
        }

        &_description {
            @extend %form__description;

            &.validation_message {
                @extend %form__error;
            }
        }

        .ginput_complex {
            display: flex;

            > * {
                margin: 0 8px;

                &:first-child {
                    margin-left: 0;
                }
            }

            .hour_minute_colon {
                line-height: 55px;
            }
        }
    }
}
