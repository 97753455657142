@import 'Styles/includes';

.Slider {
    $root: &;

    position: relative;

    &__Header {
        display: flex;
        flex-wrap: wrap;
        color: $grey-90;
        margin-top: -24px; // Compensate for margins added to header content and placeholder
        margin-botton: 24px;

        @include media(M) {
            margin-bottom: 40px;
        }
    }

    &__HeaderContent {
        flex: 0 1 auto;
        margin-top: 24px; // Add same margin top as placeholder to keep them level on same row
        max-width: map-get($maxwidths, content);
    }

    &__Placeholder {
        flex: 0 0 auto;
        align-self: flex-end;
        width: 104px;
        height: 40px;
        margin-top: 24px; // Add margin for spacing to title when placeholder is wrapped to next row
        margin-left: auto;

        @include media(M) {
            width: 128px;
            height: 48px;
        }
    }

    &__Title {
        @include textstyle(h2);
    }

    &__Text {
        @include textstyle(body-regular);

        margin-top: 16px;
    }

    &__Slider {
        margin-top: 24px;

        @include media(M) {
            margin-top: 40px;
        }
    }

    &__Empty {
        @include textstyle(body-regular);

        margin: 24px 0 0;

        @include media(M) {
            margin: 40px 0 0;
        }
    }

    &__Footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;

        @include media(M) {
            margin-top: 40px;
        }
    }

    &__Button {
        flex: 0 0 auto;
    }
}
