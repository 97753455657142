@import 'Styles/includes';

.DonationSlider {
    $root: &;

    position: relative;
    overflow: hidden;
    background: $red-5;

    &__Shell {
        @extend %shell--before;
        @extend %shell--after;

        &::before {
            top: -466px;
            right: -720px;
            width: 1258px;
            height: 892px;

            #{$root}--HideTopShell & {
                display: none;
            }
        }

        &::after {
            top: 138px;
            left: -2555px;
            width: 3052px;
            height: 2148px;

            #{$root}--HideBottomShell & {
                display: none;
            }
        }
    }

    &__Wrap {
        @include wrap;

        position: relative;
        padding-top: 48px;
        padding-bottom: 48px;
        z-index: 1;

        @include media(M) {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        @include media(ML) {
            padding-top: 80px;
            padding-bottom: 80px;
        }

        #{$root}--HideTopShell & {
            padding-top: 0 !important;
        }
    }
}
